















import { Component, Mixins } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import ListUsersDto from '@/app/users/dto/list-users.dto';
import usersService from '@/app/users/services/users.service';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import OwnersDatatable from '../components/owners-datatable.vue';

@Component({
  components: {
    OwnersDatatable,
  },
  metaInfo(this: Owners) {
    return { title: this.$t('pageTitle.owners').toString() };
  },
})
export default class Owners extends Mixins(HandlesErrorMixin) {
  private loading = true;
  private users: UserDto[] = [];
  private totalUsers = 0;
  private filters: ListUsersDto = {
    q: '',
    page: 1,
    itemsPerPage: 10,
  };

  onSearch(q: string) {
    this.filters.q = q;

    this.loadUsers();
  }

  onPageChange(page: number) {
    this.filters.page = page;

    this.loadUsers();
  }

  onUpdateItemsPerPage(itemsPerPage: number) {
    this.filters.page = 1;
    this.filters.itemsPerPage = itemsPerPage;

    this.loadUsers();
  }

  async loadUsers() {
    this.loading = true;

    try {
      const response = await usersService.listOwners(this.filters);

      this.users = response.data.items;
      this.totalUsers = response.data.totalItems;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.loadUsers();
  }
}
